import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "countries-list";

import { fetchKycDetailRequest } from "./store/actions/kycActions";
import { calculateRisk, saveResults } from "./store/actions/riskTableActions";
import { GetTabs } from "./store/actions/tabsActions";
import { GetCountriesScores } from "./store/actions/scoreActions";
import { partial_ratio } from "fuzzball";
import Loader from "./Loader";
import { GetOnboardingData } from "./store/actions/onboardingActions";
import UnsavedInvestmentResult from "./results/UnsavedInvestmentResult";
const InvestmentDetailsNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(true);
  const threshold = 70;
  const { pathname } = location;
  const { kycDetail, loading, error } = useSelector((state) => state.kyc);
  const { onboardingData } = useSelector((state) => state.onboardings);
  const { user, company } = useSelector((state) => state.user);
  const { countriesScores } = useSelector((state) => state.score);
  const [changedData, setChangedData] = useState(null);
  const [ToleranceData, setToleranceData] = useState(null);
  const [CapacityData, setCapacityData] = useState(null);
  const [UnsavedResults, setUnsavedResults] = useState(null);
  const [showUnsavedResults, setShowUnsavedResults] = useState(false);
  const kycMapping = {
    "Risk Level for Identity": "idType1",
    "Risk Level for ID Verification": "idType2",
    "Risk Level for Proof of Address": "proofOfAddressType",
    "Risk Level for Source of Income Proof": "proofOfIncomeType",
  };

  function matchField(field, value) {
    return (
      partial_ratio(field?.toLowerCase(), value?.toLowerCase()) >= threshold
    );
  }
  const listOfCountries = useMemo(() => {
    const countryList = [];
    for (let code in countries) {
      countryList.push({
        code,
        name: countries[code].name,
        fullName: `${code} - ${countries[code].name}`,
      });
    }
    return countryList.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([]);
  let FormId; // Declare kycId with let or const
  let JwtToken; // Declare JwtToken with let or const

  useEffect(() => {
    if (pathname.includes("/investment-details/")) {
      // window.location.reload()
      const afterDetails = pathname
        .split("/investment-details/")[1]
        .split("&")[0];
      if (afterDetails) {
        const parts = afterDetails.split("/");
        if (parts.length > 1) {
          FormId = parts[0];
          JwtToken = parts[1];
          localStorage.setItem("token", parts[1]);
          localStorage.setItem("FormID", parts[0]);
        } else {
          console.log("Invalid pathname format.");
        }
      } else {
        console.log("No content after '/investment-details/'.");
      }
    } else {
      setisLoading(false);
      console.log("No '/investment-details/' in pathname.");
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch({ type: "LOAD_USER_REQUEST" }); // Trigger the saga to fetch user data
    }
  }, [dispatch]);

  //   useEffect(() => {
  //     const token = localStorage.getItem("token");
  //     const kycId = localStorage.getItem("kycID");
  //     if (!kycDetail && token) {
  //       dispatch(fetchKycDetailRequest(kycId));
  //     }
  //   }, [dispatch, kycDetail]);

  const moveToDashboard = () => {
    window.location.href = "/dashboard";
  };

  // my code

  const { tabs } = useSelector((state) => state.tab);

  useEffect(() => {
    if (user) {
      if (user && !user?.riskAccess) {
        navigate("/forbidden");
      } else {
        dispatch(GetTabs(company?._id, "investment"));
      }
    }
  }, [user, company]);

  useEffect(() => {
    if (!countriesScores && company) {
      dispatch(GetCountriesScores(company?._id));
    }
  }, [dispatch, countriesScores, company]);

  useEffect(() => {
    console.log("onboardingData", onboardingData);
    console.log("FormId", FormId);
    console.log("Alltabs", tabs);
    if (!FormId) {
      FormId = localStorage.getItem("FormID");
    }
    if (!onboardingData && FormId) {
      console.log("get onboarding Data");

      dispatch(GetOnboardingData(FormId));
    }
  }, [dispatch, onboardingData, FormId]);

  console.log("onboardingData", onboardingData);
  console.log("FormId", FormId);

  const extractedArray = Object.entries(selectedOptions).map(
    ([key, option]) => ({
      name: key,
      rating: option.rating,
    })
  );

  const handleSubmit = async () => {
    let requestedBody = {
      riskLevels: data,
    };
    // e.preventDefault(); // Prevent form submission
    await dispatch(
      saveResults(
        requestedBody,
        onboardingData?._id,
        onboardingData?.companyID,
        navigate,
        "investment"
      )
    );
  };

  const [data, setData] = useState(null);

  //Handle Change

  const handleSelect = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const formObjects = onboardingData.data;

    changedData.forEach((item) => {
      // Check if the title matches the target title
      if (item.title === selectedOption.dataset.title) {
        // Update the value
        item.value = selectedOption.dataset.name;
      }
    });

    setChangedData(formObjects); // Return the updated array
  };

  function getScores(formObjects, scoresData) {
    const filteredScores = formObjects
      .map((formObject) => {
        const matchedScoreGroup = scoresData.find((scoreData) =>
          scoreData.scores.some((scoreGroup) =>
            scoreGroup.fields.some(
              (field) =>
                formObject.value
                  .toLowerCase()
                  .includes(field.title.trim().toLowerCase()) ||
                field.title
                  .trim()
                  .toLowerCase()
                  .includes(formObject.value.toLowerCase())
            )
          )
        );
        console.log("matchedScoreGroup", matchedScoreGroup);

        if (matchedScoreGroup) {
          const matchedScore = matchedScoreGroup.scores
            .flatMap((scoreGroup) => scoreGroup.fields)
            .find(
              (field) =>
                formObject.value
                  .toLowerCase()
                  .includes(field.title.trim().toLowerCase()) ||
                field.title
                  .trim()
                  .toLowerCase()
                  .includes(formObject.value.toLowerCase())
            );
          console.log("matchedScore", matchedScore);
          if (matchedScore) {
            return {
              question: formObject.title,
              value: formObject.value,
              score: matchedScore.score,
              risk: matchedScore.risk,
            };
          }
        }

        return null; // Return null if no match is found
      })
      .filter((item) => item !== null); // Filter out null values

    // Split the scores into Tolerance and Capacity
    const tolerance = filteredScores.slice(-5); // Last 5 objects
    const capacity = filteredScores.slice(0, -5); // All except last 5
    setToleranceData(tolerance);
    setCapacityData(capacity);
    // Calculate the sum of scores for each category
    const toleranceSum = tolerance.reduce((sum, item) => sum + item.score, 0);
    const capacitySum = capacity.reduce((sum, item) => sum + item.score, 0);

    return {
      Tolerance: {
        data: tolerance,
        totalScore: toleranceSum,
      },
      Capacity: {
        data: capacity,
        totalScore: capacitySum,
      },
    };
  }

  useEffect(() => {
    console.log("func started");
    if (onboardingData && tabs) {
      console.log("func started in if");
      const formObjects = onboardingData.data;
      setChangedData(formObjects);
      console.log("formObjects", formObjects);
      console.log("tabs", tabs);
      const scores = getScores(formObjects, tabs);
      console.log("Finalscores", scores);
      setData(scores);
    }
  }, [onboardingData, tabs]);

  useEffect(() => {
    if (onboardingData && data && pathname.includes("/investment-details/")) {
      handleSubmit();
    }
  }, [data, onboardingData]);

  // console.log(tabs)

  function checkKYCValue(title) {
    const kycMapping = {
      "Country of Nationality": "countryOfBirth",
      "Country of Residence": "countryOfResidence",
      "Country of Operation": "countryOfResidence",
      "Source of Income Legal Structure": "SourceOfIncomeLegalStructure.title",
      "Other Source of Income": "OtherSourcesOfIncome.title",
      "Source of Income from Industry": "SourceOfIncomeLegalStructure.title",
      "Product and Service Types": "ProductType.title",
      "Purpose of Account": "PurposeOfAccount.title",
      "PEP Status": "AccountHolderType.title",
      "Source of Income from Industry": "SourceOfIncomeFromIndustry.title",
      "Risk Level for Identity": "idType1",
      "Risk Level for ID Verification": "idType2",
      "Risk Level for Proof of Address": "proofOfAddressType",
      "Risk Level for Source of Income Proof": "proofOfIncomeType",
    };

    const kycKey = kycMapping[title];
    console.log(kycKey);
    return kycKey
      ? kycKey.split(".").reduce((obj, key) => obj?.[key], kycDetail)
      : "N/A";
  }

  function isTitleMatchingofTolerance(name, title) {
    // Iterate through each object in the array
    for (let item of ToleranceData) {
      // Check if the title matches the search string
      if (item?.value?.includes(title)) {
        return true; // Return true if a match is found
      }
    }
    return false; // Return false if no match is found
  }

  function isTitleMatchingofCapacity(name, title) {
    // Iterate through each object in the array
    for (let item of CapacityData) {
      // Check if the title matches the search string
      if (item?.value?.includes(title)) {
        return true; // Return true if a match is found
      }
    }
    return false; // Return false if no match is found
  }

  console.log("Tolerance", ToleranceData);
  console.log("Capacity", CapacityData);

  // function getScoresIfTitlesMatch(questionObj, tabsArray) {
  //   // Initialize an array to collect matching scores

  //   return tabsArray
  //     ?.map((tab) => {
  //       console.log(
  //         "questionObj?.question.trim().replace(/s+/g, ",
  //         questionObj?.question.trim().replace(/\s+/g, "")
  //       );
  //       if (
  // matchField(
  //   questionObj?.question.trim().replace(/\s+/g, ""),
  //   tab?.title.trim().replace(/\s+/g, "")
  // )
  //       ) {
  //         return tab;
  //       }
  //     })
  //     ?.filter((item) => item !== undefined);
  // }

  function getScoresIfTitlesMatch(questionObj, tabsArray) {
    // Initialize an array to collect matching scores

    return tabs
      ?.map((tab) => {
        if (
          questionObj?.question.includes(tab?.title) ||
          matchField(
            questionObj?.question.trim().replace(/\s+/g, ""),
            tab?.title.trim().replace(/\s+/g, "")
          )
        ) {
          return tab;
        }
      })
      ?.filter((item) => item !== undefined);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !showUnsavedResults ? (
        <div className="d-flex flex-column bg-light">
          {user?.accountType === "admin" ? (
            <div className="navbar">
              <div></div>
              <button
                className="btn btn-info text-white"
                onClick={moveToDashboard}
              >
                Engine Settings <span>&gt;</span>
              </button>
            </div>
          ) : null}

          <div className="container ">
            <div className="text-center py-5">
              <h3>{kycDetail?.fullName}</h3>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-8">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const scores = getScores(changedData, tabs);
                    // console.log("scores", scores);
                    setUnsavedResults(scores);
                    setShowUnsavedResults(true);
                    // e.preventDefault(); // Prevent form submission
                  }}
                >
                  {/* Country Operation */}
                  {ToleranceData &&
                    ToleranceData?.map((d, i) => (
                      <div className="card border-primary mb-4 p-1 rounded-3">
                        <div className="bg-secondary-subtle card-header">
                          <h4 className="mb-2 text-primary">{d.question}</h4>
                        </div>
                        <div className="card-body rounded-bottom-3 bg-light-blue">
                          <div className="row">
                            <label className="col-sm-5 col-form-label">
                              {d.value}
                            </label>
                            <div className="col-sm-7">
                              <Form.Select
                                aria-label="Default select example"
                                name={d.title}
                                // value={countryOperation.id || ""}
                                onChange={handleSelect}
                              >
                                <option>Open this select menu</option>
                                {getScoresIfTitlesMatch(
                                  d
                                )?.[0]?.scores?.[0]?.fields?.map(
                                  (fieldOptions) => (
                                    <option
                                      selected={isTitleMatchingofTolerance(
                                        d.question,
                                        fieldOptions.title
                                      )}
                                      name={d.question}
                                      value={fieldOptions.score}
                                      data-default={d.question}
                                      data-title={d.question}
                                      data-name={fieldOptions.title}
                                    >
                                      {fieldOptions.title}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {CapacityData &&
                    CapacityData?.map((d, i) => (
                      <div className="card border-primary mb-4 p-1 rounded-3">
                        <div className="bg-secondary-subtle card-header">
                          <h4 className="mb-2 text-primary">{d.question}</h4>
                        </div>
                        <div className="card-body rounded-bottom-3 bg-light-blue">
                          <div className="row">
                            <label className="col-sm-5 col-form-label">
                              {d.value}
                            </label>
                            <div className="col-sm-7">
                              <Form.Select
                                aria-label="Default select example"
                                name={d.title}
                                // value={countryOperation.id || ""}
                                onChange={handleSelect}
                              >
                                <option>Open this select menu</option>
                                {getScoresIfTitlesMatch(
                                  d
                                )?.[0]?.scores?.[0]?.fields?.map(
                                  (fieldOptions) => (
                                    <option
                                      selected={isTitleMatchingofCapacity(
                                        d.question,
                                        fieldOptions.title
                                      )}
                                      name={d.question}
                                      value={fieldOptions.score}
                                      data-default={d.question}
                                      data-title={d.question}
                                      data-name={fieldOptions.title}
                                    >
                                      {fieldOptions.title}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* Submit Button */}
                  <div className="text-center">
                    <Button variant="primary" type="submit">
                      Calculate Risk
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UnsavedInvestmentResult
          showUnsavedResults={showUnsavedResults}
          setShowUnsavedResults={setShowUnsavedResults}
          apiData={UnsavedResults}
        />
      )}
    </>
  );
};

export default InvestmentDetailsNew;
